import close from "../image/close.png";
import ProductItem from "../component/ProductItem";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../redux/actions";
import notfound from "../image/not-found.png";
import loading from "../image/loading-gif.gif";

function Main() {
  const [visibleProducts, setVisibleProducts] = useState(12); // начальное состояние
  // Используйте useSelector для доступа к состоянию фильтров
  const filters = useSelector((state) => state.filters);

  // Теперь у вас есть доступ к параметрам фильтра
  const { season, width, height, diameter, brand } = useSelector(
    (state) => state.filters.filters
  );

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 12); // увеличиваем количество отображаемых товаров на 12
  };

  const dispatch = useDispatch();

  const removeFilter = (filterName) => {
    let attributeName = "";

    switch (filterName) {
      case "season":
        attributeName = "data-season";
        break;
      case "width":
        attributeName = "data-width";
        break;
      case "height":
        attributeName = "data-height";
        break;
      case "diameter":
        attributeName = "data-diameter";
        break;
      case "brand":
        attributeName = "data-brand";
        break;
      default:
        return; // Если filterName не соответствует ни одному из известных, выходим из функции
    }

    // Удаляем атрибут из элементов DOM
    const elements = document.querySelectorAll(`.search[${attributeName}]`);
    elements.forEach((el) => el.setAttribute(attributeName, ""));

    // Обновляем фильтры в состоянии Redux, указывая, что фильтр был удален
    dispatch(updateFilters({ ...filters, [filterName]: "" }));
  };

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!brand && !season && !width && !height && !diameter) {
      fetch("https://lp.rezina13.com.ua/tires-new")
        .then((response) => response.json())
        .then((fetchedData) => setData(fetchedData));
    } else {
      setData(null);
      const queryParams = '?'
        + (season ? 'season=' + season + '&' : '')
        + (brand ? 'brand=' + brand + '&' : '')
        + (width ? 'width=' + width + '&' : '')
        + (height ? 'height=' + height + '&' : '')
        + (diameter ? 'diameter=' + diameter : '');
      fetch("https://lp.rezina13.com.ua/tires-new-search" + queryParams)
        .then((response) => response.json())
        .then((fetchedData) => setData(fetchedData));
    }
  }, [brand, season, width, height, diameter]);

  return (
    <>
      <section className="main" id="main">
        <h2>Результат за Вашими параметрами</h2>
        <div className="container">
          <div className="fillter-result_area">
            <span className="title">Обрані параметри:</span>
            {season && (
              <div className="fra_item">
                {season}
                <a className="close" onClick={() => removeFilter("season")}>
                  <img src={close} alt="" />
                </a>
              </div>
            )}
            {width && (
              <div className="fra_item">
                {width}
                <a className="close" onClick={() => removeFilter("width")}>
                  <img src={close} alt="" />
                </a>
              </div>
            )}
            {height && (
              <div className="fra_item">
                {height}
                <a className="close" onClick={() => removeFilter("height")}>
                  <img src={close} alt="" />
                </a>
              </div>
            )}
            {diameter && (
              <div className="fra_item">
                {diameter}
                <a className="close" onClick={() => removeFilter("diameter")}>
                  <img src={close} alt="" />
                </a>
              </div>
            )}
            {brand && (
              <div className="fra_item">
                {brand}
                <a className="close" onClick={() => removeFilter("brand")}>
                  <img src={close} alt="" />
                </a>
              </div>
            )}
          </div>

          <div className="catalog">
            {data ? data.length > 0 ? (
              data
                .slice(0, visibleProducts)
                .map((obj, index) => <ProductItem key={index} {...obj} />)
            ) : (
              <div className="notfound">
                <img src={notfound} alt="" />
                <p>Нажаль за цими параметрами нічого не знайдено</p>
              </div>
            ) : (
              <div className="notfound">
                <img src={loading} alt="" />
                <p>Завантаження...</p>
              </div>
            )}
          </div>
          <button className="load" onClick={loadMoreProducts}>
            Завантажити Ще
          </button>
        </div>
      </section>
    </>
  );
}

export default Main;
