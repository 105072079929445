import Social from "../component/Social";
import FilterDiski from "../component/FilterDiski";
import logo from "../image/logo.png";
import phone from "../image/phone-white.png"
import React, { useState, useEffect } from 'react';
import Menu from "../dom/Menu";
import ProductItem from "./ProductItem";
import close from '../image/close.png'
import Footer from "../dom/Footer";
import notfound from "../image/not-found.png";
import loading from "../image/loading-gif.gif";

function DiskiList() {
    const [season, setSeason] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [diameter, setDiameter] = useState('');
    const [brand, setBrand] = useState('');
    const [visibleProducts, setVisibleProducts] = useState(12); // начальное состояние
    const loadMoreProducts = () => {
        setVisibleProducts(prevVisibleProducts => prevVisibleProducts + 12); // увеличиваем количество отображаемых товаров на 12
    };

    useEffect(() => {
        const savedSeason = localStorage.getItem('season');
        const savedWidth = localStorage.getItem('width');
        const savedHeight = localStorage.getItem('height');
        const savedDiameter = localStorage.getItem('diameter');
        const savedBrand = localStorage.getItem('brand');
      
        if (savedSeason) setSeason(savedSeason);
        if (savedWidth) setWidth(savedWidth);
        if (savedHeight) setHeight(savedHeight);
        if (savedDiameter) setDiameter(savedDiameter);
        if (savedBrand) setBrand(savedBrand);
      }, []);
      
      useEffect(() => {
        localStorage.setItem('season', season);
        localStorage.setItem('width', width);
        localStorage.setItem('height', height);
        localStorage.setItem('diameter', diameter);
        localStorage.setItem('brand', brand);
      }, [season, width, height, diameter, brand]);
    const updateFilter = (filterType, value) => {
        switch (filterType) {
            case 'season':
                setSeason(value);
                break;
            case 'width':
                setWidth(value);
                break;
            case 'height':
                setHeight(value);
                break;
            case 'diameter':
                setDiameter(value);
                break;
            case 'brand':
                setBrand(value);
                break;
            default:
                break;
        }
    };

    const [activeFilter, setActiveFilter] = useState('param');

    useEffect(() => {
        // Находим все элементы с data-fillter="param" или data-fillter="mark"
        const filterItemsParam = document.querySelectorAll('.item[data-fillter="param"]');
        const filterItemsMark = document.querySelectorAll('.item[data-fillter="mark"]');

        // Удаляем класс 'active' у всех элементов
        filterItemsParam.forEach(item => item.classList.remove('active'));
        filterItemsMark.forEach(item => item.classList.remove('active'));

        // Добавляем класс 'active' к элементам в зависимости от выбранного фильтра
        if (activeFilter === 'param') {
            filterItemsParam.forEach(item => item.classList.add('active'));
        } else if (activeFilter === 'mark') {
            filterItemsMark.forEach(item => item.classList.add('active'));
        }
    }, [activeFilter]); // Эффект будет повторно запускаться при изменении activeFilter

    const handleFilterClick = (event, filterType) => {
        event.preventDefault();
        setActiveFilter(filterType);
        const filter_result_block = document.querySelector('.fillter-result')
        filter_result_block.classList.remove('active');
    };

    const removeFilter = (filterName) => {
        switch (filterName) {
            case 'season':
                setSeason('');
                break;
            case 'width':
                setWidth('');
                break;
            case 'height':
                setHeight('');
                break;
            case 'diameter':
                setDiameter('');
                break;
            case 'brand':
                setBrand('');
                break;
            default:
                break;
        }
    }

    const [data, setData] = useState(null);
    useEffect(() => {
        if (!brand && !season && !width && !height && !diameter) {
            fetch("https://lp.rezina13.com.ua/diski-new")
              .then((response) => response.json())
              .then((fetchedData) => setData(fetchedData));
          } else {
            setData(null);
            const queryParams = '?'
              + (season ? 'width=' + season + '&' : '')
              + (width ? 'pcd1=' + width + '&' : '')
              + (height ? 'diameter=' + height + '&' : '')
              + (diameter ? 'dia=' + diameter + '&' : '')
              + (brand ? 'et=' + brand : '');
            fetch("https://lp.rezina13.com.ua/diski-new-search" + queryParams)
              .then((response) => response.json())
              .then((fetchedData) => setData(fetchedData));
          }
    }, [brand, season, width, height, diameter]);

    return (
        <>
         <header className="diski">
            <div className="container">
                <div className="top_line">
                    <div className="logo">
                        <a href="" className="logo"><img src={logo} alt="" /></a>
                    </div>
                    <Menu />
                    <Social />
                    <div className="phone_area">
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:095 706 45 00">095 706 45 00</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:098 858 23 80">098 858 23 80</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:093 707 88 01">093 707 88 01</a></div>

                    </div>
                </div>

                <div className="hero">
                    <h1>Обери ідеальні <br /> автомобільні диски з Rezina13</h1>

                        <div className="navigation_filter">
                            <a  className={activeFilter === 'param' ? 'active' : ''} onClick={(event) => handleFilterClick(event,'param')} data-fill="param">За параметрами</a>
                            <a  className={activeFilter === 'mark' ? 'active' : ''} onClick={(event) => handleFilterClick(event,'mark')} data-fill="mark">По марці авто</a>   
                        </div>

                        <FilterDiski updateFilter={updateFilter} activeFilter={activeFilter} />
                </div>
            </div>
        </header>
        <section className="main" id="main">
            <h2>Результат за Вашими параметрами</h2>
            <div className="container">
                <div className="fillter-result_area">
                    <span className="title">Обрані параметри:</span>
                    {season && season !== null && <div className="fra_item">{season}<a className="close" onClick={() => removeFilter('season')}><img src={close} alt="" /></a></div>}
                    {width && width !== null && <div className="fra_item">{width}<a className="close"  onClick={() => removeFilter('width')}><img src={close} alt="" /></a></div>}
                    {height && height !== null && <div className="fra_item">{height}<a className="close"  onClick={() => removeFilter('height')}><img src={close} alt="" /></a></div>}
                    {diameter && diameter !== null && <div className="fra_item">{diameter}<a className="close"  onClick={() => removeFilter('diameter')}><img src={close} alt="" /></a></div>}
                    {brand && brand !== null && <div className="fra_item">{brand}<a className="close"  onClick={() => removeFilter('brand')}><img src={close} alt="" /></a></div>}
                </div>

                <div className="catalog">
                    {data ? data.slice(0, visibleProducts).length > 0 ?
                    (
                        data.slice(0, visibleProducts).map((obj, index) => <ProductItem key={index} {...obj}  />)
                    ) : (
                        <div className="notfound">
                            <img src={notfound} alt="" />
                            <p>Нажаль за цими параметрами нічого не знайдено</p>
                        </div>
                    ) : (
                        <div className="notfound">
                            <img src={loading} alt="" />
                            <p>Завантаження...</p>
                        </div>
                    )}
                </div>
                <button className="load"  onClick={loadMoreProducts}>Завантажити Ще</button>
            </div>
        </section>
        <Footer />
        </>
    )
}

export default DiskiList;